import React, { ReactNode } from 'react'
import Button, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import Link from '../link'
import { useCtaContext } from '../../hooks/useCtaContext'

interface IButtonWithChildren {
  children: ReactNode
  label?: never
}

interface IButtonWithLabel {
  children?: never
  label: string
}

export type ILinkButtonProps = (IButtonWithChildren | IButtonWithLabel) & {
  url: string
  newTab?: boolean
  buttonProps: MuiButtonProps
}

const LinkButton: React.FC<ILinkButtonProps> = (props) => {
  const { children, label, url, newTab, buttonProps } = props

  const { setCtaData } = useCtaContext()

  if (!url) {
    return null
  }

  if (url.startsWith('#')) {
    const handleScroll = () => {
      const [ctaId, ctaSearch] = url.slice(1).split('?')

      if (ctaSearch) {
        try {
          const newData = ctaSearch
            .split('&')
            .map((param) => param.split('='))
            .filter(([key]) => key.includes('cta-'))
            .reduce((prev, [key, value]) => ({ ...prev, [key.split('cta-')[1]]: value }), {})

          setCtaData(newData)
        } catch (error) {
          console.log('Cannot parse cta params')
        }
      }

      const ctaElement = document.getElementById(ctaId)
      ctaElement?.style.setProperty('scroll-margin-top', '114px')
      ctaElement?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
      <Button
        {...buttonProps}
        onClick={handleScroll}
      >
        {children || label}
      </Button>
    )
  }

  return (
    <Button
      LinkComponent={Link}
      href={url}
      {...newTab && { target: '_blank' }}
      {...buttonProps}
    >
      {children || label}
    </Button>
  )
}

export default LinkButton
