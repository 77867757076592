import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { Theme } from '@mui/material/styles'
import { SectionComponentType } from '../constants/sections'
import { SocialType } from '../constants/socials'
import { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image'
import { TextFieldProps } from '@mui/material'
import { FieldComponentType } from '../constants/fields'
import { IMainFormSectionData } from '../components/page-sections/main-form-section/MainFormSection'

export enum IStrapiImagePosition {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  DOWN = 'down',
}

export enum IStrapiQuoteImagePosition {
  DEFAULT = 'default',
  FLOATING = 'floating',
}

export enum IStrapiImageSize {
  EXTRA_LARGE = 'extra-large',
  LARGE = 'large',
  NORMAL = 'normal',
  SMALL = 'small'
}

export interface IStrapiImage {
  localFile?: IGatsbyFile
  width: string
  height: string
  url: string
  alternativeText?: string
  name?: string
  caption?: string
  fields?: {
    width: string
    height: string
  }
}

export interface IStrapiFile {
  localFile?: ImageDataLike & {
    publicURL: string
  }
  name?: string
}

export interface IStrapiCase {
  url: string
  title: string
  description?: string
  image: IStrapiImage
  imageOverlay?: string
  location: IStrapiLocation
  services: Array<IStrapiService>
  industries: Array<IStrapiIndustry>
  technologies: string

  cover?: IStrapiImage

  customer?: string

  challenge?: string

  solution?: string
  solutionList?: Array<IStrapiListItem>
  solutionImages?: Array<IStrapiImageCard>

  results?: string
  resultsList?: Array<IStrapiListItem>

  websiteType?: IStrapiWebsiteType
  task?: string
  duration?: string
  team?: string

  quoteText?: string
  quoteLogo?: IStrapiImage
  quoteAuthor?: string
  quoteAuthorJobTitle?: string

  techStack?: string
  technologiesTabs?: Array<IStrapiTabItem>

  filePDF?: IStrapiFile
  imagePDF?: IStrapiImage

  caseStudies?: string
  casesCarousel?: Array<IStrapiCase>

  mainForm?: IMainFormSectionData

  seo?: IStrapiSeo
}

export interface IStrapiTabItem {
  name: string
  values: string
}

export interface IStrapiBaseLink {
  url: string
  newTab?: boolean
  nofollow?: boolean
}

export interface IStrapiSimpleLink {
  label: string
  link: IStrapiBaseLink
}

export type IGatsbyFile = ImageDataLike & {
  publicURL: string
  extension: string
  svg?: {
    content: string
    originalContent?: string
  }
  childImageSharp?: {
    gatsbyImageData: IGatsbyImageData
  }
}

export interface IStrapiMenuLink {
  label: string
  link: IStrapiBaseLink
  icon: IStrapiImage
  iconFile?: IGatsbyFile
}

export interface IStrapiSubMenu {
  label: string
  sections: Array<IStrapiMenuSection>
  banner?: IStrapiBanner
}

export interface IStrapiMenuSection {
  links: Array<IStrapiMenuLink>
}

export interface IStrapiScript {
  src: string
  text: string
  async: boolean
}

export interface IStrapiSeo {
  title?: string
  description?: string
  disableIndexing?: boolean
}

export interface IStrapiTheme {
  primaryTextColor: string
  secondaryTextColor: string
  backgroundColor: string
}

export interface IStrapiButton {
  label: string
  url?: string
  newTab?: boolean
  variant?: MuiButtonProps['variant']
  color?: MuiButtonProps['color']
  size?: MuiButtonProps['size']
  file?: IStrapiFile
}

export interface IStrapiSocial {
  url: string
  name: string
  icon: SocialType
}

export interface IStrapiSocialProps {
  color?: string
  background?: string
  hoverColor?: string
  hoverBackground?: string
  gap: 'small' | 'medium' | 'large'
  size: 'small' | 'medium' | 'large'
}

export interface IStrapiSocialsBlock {
  socialProps: IStrapiSocialProps
  socials: Array<IStrapiSocial>
}

export enum IStrapiTitleComponent {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  PARAGRAPH = 'p',
}

export interface IStrapiTitle {
  text: string
  component: IStrapiTitleComponent
}

export interface IStrapiPriceTag {
  content?: IStrapiWysiwyg
  price: string
}

export interface IStrapiSection {
  strapi_component: SectionComponentType
  options: IStrapiSectionOptions
}
export interface IStrapiSectionBackground {
  xPosition: 'left' | 'center' | 'right'
  yPosition: 'top' | 'center' | 'bottom'
  size: 'cover' | 'contain' | 'auto'
  image: IStrapiImage
  mobileOpacity?: number
  desktopOpacity?: number
  mixMode?: IStrapiMixMode
  overlay?: string
}

export enum IStrapiMixMode {
  NORMAL = 'normal',
  OVERLAY = 'overlay',
}

export enum IStrapiPadding {
  EXTRA_LARGE = 'extra-large',
  LARGE = 'large',
  NORMAL = 'normal',
  SMALL = 'small',
  OFF = 'off',
}

export enum IStrapiSectionWidth {
  MAX_700 = 'max-700',
  MAX_800 = 'max-800',
  MAX_900 = 'max-900',
  FULL_WIDTH = 'full-width'
}

export interface IStrapiSectionOptions {
  anchor?: string
  paddingTop?: IStrapiPadding
  paddingBottom?: IStrapiPadding
  theme?: IStrapiTheme
  background?: IStrapiSectionBackground
  width?: IStrapiSectionWidth
}

export interface IStrapiTabElement {
  label: string
  sectionId: string
}

export enum IStrapiTabIndicator {
  NONE = 'none',
  UNDERLINE = 'underline',
  BUTTON = 'button',
}

export enum IStrapiTabsPosition {
  LEFT = 'left',
  TOP = 'top',
  CENTER = 'top-centered',
}

export interface IStrapiBackground extends Partial<IStrapiSectionBackground> {
  color?: string
  blur?: boolean
}

export interface IStrapiBasicCard {
  image?: IStrapiImage
  title?: IStrapiTitle
  background?: IStrapiBackground
  content: IStrapiWysiwyg
  link?: IStrapiBaseLink
}

export enum IStrapiTextSize {
  LARGE = 'large',
  NORMAL = 'normal',
  SMALL = 'small',
}

export interface IStrapiTextCard {
  wysiwyg: IStrapiWysiwyg
}

export interface IStrapiActionCard {
  content: IStrapiWysiwyg
  details: IStrapiWysiwyg
  backgroundColor?: string
  link?: IStrapiBaseLink
}

export enum IStrapiActionCardsEffect {
  ACCORDION = 'accordion',
  FLIP = 'flip',
  ROLE_ACCORDION = 'role-accordion',
  FULL_ACCORDION = 'full-accordion',
  SLIDE_DOWN = 'slide-down',
}

export interface IStrapiImageCard {
  image: IStrapiImage
}

export interface IStrapiLicenseCard {
  content: IStrapiWysiwyg
  price?: string
  image?: IStrapiImage
  backgroundColor?: string
  button?: IStrapiButton
  link?: IStrapiBaseLink
}

export interface IStrapiSimpleCard {
  content: IStrapiWysiwyg
  image?: IStrapiImage
}

export enum IStrapiDesktopAlignment {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum IStrapiMobileAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface IStrapiPriceCard {
  title: IStrapiTitle
  price: string
  features: string
}

export enum IStrapiCardAlignment {
  LEFT = 'left',
  CENTER = 'center',
}

export interface IStrapiCardStyle {
  rounded: boolean
  shadow: boolean
  hoverable: boolean
  alignment: IStrapiCardAlignment
  backgroundColor?: string
  background?: IStrapiSectionBackground
  cardPadding?: IStrapiPadding
}

export enum IStrapiGridItemSize {
  AUTO = 'auto',
  SIZE_25 = 'size-25',
  SIZE_33 = 'size-33',
  SIZE_40 = 'size-40',
  SIZE_50 = 'size-50',
  SIZE_60 = 'size-60',
  SIZE_66 = 'size-66',
  SIZE_75 = 'size-75',
  SIZE_100 = 'size-100',
}

export enum IStrapiGridSpacing {
  OFF = 'off',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
  EXTRA_LARGE = 'x-large',
}

export interface IStrapiGridElement {
  sectionId: string
  width: IStrapiGridItemSize
  mobileBehavior?: IStrapiGridBehavior
}

export enum IStrapiGridBehavior {
  MOBILE_FIRST = 'mobile-first',
  MOBILE_LAST = 'mobile-last',
  MOBILE_HIDDEN = 'mobile-hidden',
  DESKTOP_HIDDEN = 'desktop-hidden',
  DEFAULT = 'default',
}

export interface IStrapiReview {
  review: string
  author: string
  authorDescription?: string
  image: IStrapiImage
  title?: string
  reviewMore?: string
}

export interface IStrapiReviewSlide {
  review: string
  author: string
  site?: string
  image: IStrapiImage
  title?: string
  reviewMore?: string
}

export enum IStrapiButtonPosition {
  DOWN = 'down',
  RIGHT = 'right',
}

export interface IStrapiMarkdown {
  data: {
    childMarkdownRemark: {
      html: string
    }
  }
  medias?: Array<{
    src: string
    localFile: {
      publicURL: string
    }
    file: {
      width: number
      height: number
    }
  }>
}
export interface IStrapiWysiwyg {
  data: {
    childMarkdownRemark: {
      fields: {
        fixedHtml: string
      }
      images: Array<{
        url: string
        publicURL: string
      }>
    }
  }
}

export interface IStrapiListItem {
  title?: string
  content?: string
  link?: IStrapiBaseLink
  icon?: IStrapiImage
}

export type IStrapiListIconColor = keyof Pick<Theme['palette'], 'primary' | 'secondary'>

export enum IStrapiListStyle {
  NONE = 'none',
  DASH = 'dash',
  CHECK = 'check',
  FILLED_CHECK = 'filled-check',
  UPPERLINE = 'upperline',
  LINK = 'link',
  STEP = 'step',
  DOTS_SQUARE = 'dots-square',
  DISC = 'disc',
}

export interface IStrapiAddress {
  title: string
  address: string
  icon: IStrapiImage
}

export interface IStrapiImageLink {
  image: IStrapiImage
  link?: IStrapiBaseLink
}

export interface IStrapiFooterLink {
  isTitle?: boolean
  label: string
  link?: IStrapiBaseLink
}

export interface IStrapiFooterSection {
  links: Array<IStrapiFooterLink>
  wpPosts?: {
    postsCount: number
    nofollow?: boolean
  }
}

export interface IStrapiFooter {
  theme: IStrapiTheme
  logo: IStrapiImageLink
  email: string
  addresses: Array<IStrapiAddress>
  sections: Array<IStrapiFooterSection>
  commonLinks: Array<IStrapiSimpleLink>
  copyright: string
  socials: Array<IStrapiSocial>
  socialProps: IStrapiSocialProps
}

export interface IStrapiHeader {
  theme: IStrapiTheme
  topBar: IStrapiTopBar
  logo: IStrapiImageLink
  button: IStrapiButton
  subMenus: Array<IStrapiSubMenu>
  links: Array<IStrapiSimpleLink>
}

export interface IStrapiCarouselProps {
  slidesPerView: number
  slidesPerGroup?: number
  pagination: boolean
  autoplay: boolean
  creeping: boolean
}
export interface IStrapiBlogPost {
  url: string
  title: string
  slug: string
  image?: IStrapiImage
  imageUrl: string
  wpImage?: IGatsbyFile
}

export interface IStrapiTopBar {
  disabled: boolean
  title?: IStrapiTitle
  button: IStrapiButton
  theme?: IStrapiTheme
}

export enum IStrapiContentPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IStrapiBanner {
  title: IStrapiTitle
  button: string
  theme?: IStrapiTheme
  link: IStrapiBaseLink
  contentPosition: IStrapiContentPosition
  pictures: Array<IStrapiImage>
}

export enum IStrapiPageElementType {
  META = 'meta',
  LINK = 'link',
  SCRIPT = 'script',
}

export enum IStrapiPageElementAssignment {
  DEV = 'development',
  PROD = 'production',
  COMMON = 'common',
  OFF = 'off',
}

export interface IStrapiPageElementAttribute {
  key: string
  value?: string
}

export interface IStrapiPageElement {
  type: IStrapiPageElementType
  assignment: IStrapiPageElementAssignment
  body?: string
  attributes?: Array<IStrapiPageElementAttribute>
}

export enum IStrapiColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TEXT = 'text',
}

export interface IStrapiRow {
  key: string
  value: IStrapiMarkdown
}

export enum IStrapiIconPosition {
  TOP = 'top',
  TOP_CENTERED = 'top-centered',
  LEFT = 'left',
  LEFT_CENTERED = 'left-centered',
}

export enum IStrapiButtonsAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum IStrapiFlexAlignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum IStrapiFlexDirection {
  ROW = 'row',
  COLUMN = 'column',
}

export interface IStrapiIconListItem {
  title?: IStrapiTitle
  description?: string
  icon?: IStrapiImage
  iconSize?: IStrapiIconSize
}

export enum IStrapiIconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

export enum IStrapiImageMobileBehavior {
  HIDE_IMAGE = 'image-hide',
  IMAGE_FIRST = 'image-first',
  CONTENT_FIRST = 'content-first',
}

export enum IStrapiFormTemplate {
  NEW = 'new',
  OLD = 'old',
  NFT = 'nft',
}

export interface IStrapiValidationRequired {
  value: boolean
  message: string
}

export interface IStrapiValidationMaxFileSize {
  value: number
  message: string
}

export interface IStrapiValidationMaxFilesCount {
  value: number
  message: string
}

export interface IStrapiValidationPattern {
  regExp: string
  message: string
}

export interface IStrapiFieldStyle {
  color: 'primary' | 'secondary' | 'text'
  variant: TextFieldProps['variant']
}

export interface IStrapiOption {
  value: string
  title: string
}

export interface IStrapiFieldBase {
  strapi_component: FieldComponentType
  name: string
  required?: IStrapiValidationRequired
}

export interface IStrapiTextInput extends IStrapiFieldBase {
  strapi_component: FieldComponentType.TEXT
  placeholder: string
  multiline: boolean
  autocomplete: string
  pattern?: IStrapiValidationPattern
}

export interface IStrapiFileInput extends IStrapiFieldBase {
  strapi_component: FieldComponentType.FILE
  supportedFormats: string
  maxFilesCount?: IStrapiValidationMaxFileSize
  maxFileSizeMb?: IStrapiValidationMaxFilesCount
}

export interface IStrapiSelect extends IStrapiFieldBase {
  strapi_component: FieldComponentType.SELECT
  placeholder: string
  options: Array<IStrapiOption>
}

export interface IStrapiCheckbox extends IStrapiFieldBase {
  strapi_component: FieldComponentType.CHECKBOX
  placeholder: string
}

export type IStrapiFormField = IStrapiTextInput | IStrapiSelect | IStrapiFileInput | IStrapiCheckbox

export interface IStrapiLocation {
  name: string
  icon: IStrapiImage
}

export interface IStrapiService {
  name: string
  url?: string
  filterUrl: string
  filterTitle?: string
  seo?: IStrapiSeo
}

export interface IStrapiIndustry {
  name: string
  filterUrl: string
  filterTitle?: string
  seo?: IStrapiSeo
}

export interface IStrapiWebsiteType {
  name: string
  url?: string
}

export interface IStrapiPage {
  seo: IStrapiSeo
  sections: Array<IStrapiSection>
  mainForm: IMainFormSectionData
  preloadImage: IStrapiImage
}

export interface IStrapiStepItem {
  image: IStrapiImage
  content: IStrapiWysiwyg
}

export enum IStrapiStepsTemplate {
  COLUMN_NUMBERED = 'column-numbered',
  ROW_NUMBERED = 'row-numbered',
  CARD_ARROWS = 'cards-arrows',
  NFT_STEPS = 'NFT-work-steps',
}

export interface IStrapiFaqItem {
  question: string
  answer: IStrapiWysiwyg
}

export interface IStrapiKeyValueItem {
  key: string
  value: IStrapiWysiwyg
}

export enum IStrapiLIconListItemsType {
  DEFAULT = 'default',
  INTERLEAVED = 'interleaved',
  INTERLEAVED_ROW = 'interleaved-row',
}

export enum IStrapiFAQTemplate {
  BRAND = 'brand',
  NFT = 'nft'
}

export interface IStrapiPriceLabelCard {
  label?: string
  price: string
  header: IStrapiWysiwyg
  content: IStrapiWysiwyg
  button: IStrapiButton
}

export enum IStrapiImagesCarouselTemplate {
  DEFAULT = 'default',
  CARD_GRAYSCALE = 'card-grayscale'
}

export enum IStrapiBlogTemplate {
  MANUAL = 'manual',
  LATEST = 'latest'
}

export enum IStrapiCasesCardsTemplate {
  DEFAULT = 'default',
  HOME_GRID = 'home-grid'
}

export enum IStrapiPriceLabelCardsTemplate {
  CLOUD_HOSTING = 'cloud-hosting',
  VPS_HOSTING = 'vps-hosting'
}

export enum IStrapiPageLayout {
  PAGE = 'page',
  LANDING = 'landing',
  CASES_HOME = 'cases-home',
  CASES_FILTER = 'cases-filter',
}

export interface IStrapiForm {
  name: string
  mobileName: string
  successMessage: string
  submitTitle: string
  agreement: IStrapiWysiwyg
  fieldStyle: IStrapiFieldStyle
  formFields: Array<IStrapiFormField>
}

export interface IStrapiPriceOfferCard {
  title: string
  plan: string
  price: string
  image: IStrapiImage
  color: string
  offerItems: Array<IStrapiOfferItem>
  button: IStrapiButton
}

export interface IStrapiOfferItem {
  label: IStrapiWysiwyg
  value: IStrapiWysiwyg
}
